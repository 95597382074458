<template>
  <!-- <div id="footer" class="d-flex justify-content-center align-items-center"> -->
  <div class="mt-5 bg-white">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="py-2 px-3 w-100" style="background-color: #ededed">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <img
              src="/assets/img/logo/logo-dark.png"
              alt="PrimeCodex"
              class="img-fluid"
              style="width: 140px"
            />
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center py-2">
                <a
                  href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=supportprimecodex.zendesk.com&settings=JTdCJTIyd2ViV2lkZ2V0JTIyJTNBJTdCJTIyY2hhdCUyMiUzQSU3QiUyMnRpdGxlJTIyJTNBbnVsbCUyQyUyMm1lbnVPcHRpb25zJTIyJTNBJTdCJTIyZW1haWxUcmFuc2NyaXB0JTIyJTNBdHJ1ZSU3RCUyQyUyMmRlcGFydG1lbnRzJTIyJTNBJTdCJTdEJTJDJTIycHJlY2hhdEZvcm0lMjIlM0ElN0IlMjJkZXBhcnRtZW50TGFiZWwlMjIlM0FudWxsJTJDJTIyZ3JlZXRpbmclMjIlM0FudWxsJTdEJTJDJTIyb2ZmbGluZUZvcm0lMjIlM0ElN0IlMjJncmVldGluZyUyMiUzQW51bGwlN0QlMkMlMjJjb25jaWVyZ2UlMjIlM0ElN0IlMjJhdmF0YXJQYXRoJTIyJTNBbnVsbCUyQyUyMm5hbWUlMjIlM0FudWxsJTJDJTIydGl0bGUlMjIlM0FudWxsJTdEJTdEJTJDJTIyY29sb3IlMjIlM0ElN0IlMjJhcnRpY2xlTGlua3MlMjIlM0ElMjIlMjIlMkMlMjJidXR0b24lMjIlM0ElMjIlMjIlMkMlMjJoZWFkZXIlMjIlM0ElMjIlMjIlMkMlMjJsYXVuY2hlciUyMiUzQSUyMiUyMiUyQyUyMmxhdW5jaGVyVGV4dCUyMiUzQSUyMiUyMiUyQyUyMnJlc3VsdExpc3RzJTIyJTNBJTIyJTIyJTJDJTIydGhlbWUlMjIlM0FudWxsJTdEJTdEJTdE&mid=&locale=en-us&title=Web%20Widget%20Live%20Chat"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-dark no-underline"
                >
                  <p class="border-right m-0 p-1 px-2" style="font-size: 12px">
                    Contact Us
                  </p>
                </a>
                <a
                  href="https://primecodex.com/assets/documents/Legal%20Information%20Prime%20Codex%20LLC.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-dark no-underline"
                >
                  <p class="m-0 p-1 px-2 text-xs" style="font-size: 12px">
                    Legal Documentation
                  </p>
                </a>
              </div>
              <div class="d-flex align-items-center">
                <a
                  href="https://www.instagram.com/primecodexfx/"
                  target="_blank"
                  rel="noopener nofererrer"
                  class="mx-1"
                >
                  <img
                    src="/assets/img/icon/instagram.png"
                    alt="Instagram"
                    class="img-fluid"
                    style="width: 30px"
                  />
                </a>
                <a
                  href="https://twitter.com/primecodexfx"
                  target="_blank"
                  rel="noopener nofererrer"
                  class="mx-1"
                >
                  <img
                    src="/assets/img/icon/twitter.png"
                    alt="Twitter"
                    class="img-fluid"
                    style="width: 30px"
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100089658592889"
                  target="_blank"
                  rel="noopener nofererrer"
                  class="mx-1"
                >
                  <img
                    src="/assets/img/icon/facebook.png"
                    alt="Facebook"
                    class="img-fluid"
                    style="width: 30px"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="border-bottom">
          <div class="p-3">
            <p class="text-secondary text-justify" style="font-size: 10px">
              Prime Codex is operated by Prime Codex LLC and has registered in
              Saint Vincent & the Grenadines with LLC number 892 LLC 2021. We
              have all heard the phrase “high risk, high return” when it comes
              to the financial market. It's important to remember that forex and
              CFDs are leveraged products with a high level of risk. It is
              possible to lose all your capital. Make sure that you have fully
              understood the risks involved and implement proper risk management
              or seek independent advice if necessary.
            </p>
            <p class="text-secondary text-justify" style="font-size: 10px">
              Prime Codex LLC is regulated by the Comoros Union with
              International Brokerage and Clearing Houses License. License
              Number: T2023334
            </p>
            <p class="text-secondary text-justify" style="font-size: 10px">
              As a broker company, we strictly comply with international laws
              and regulations related to trading activities. Prime Codex LLC
              does not provide services for citizens/residents of the United
              States, Japan, Canada, Australia, North Korea, United Kingdom,
              Iran, Syria, Sudan & Cuba. The services of Prime Codex LLC are not
              intended for distribution to or use by, any person in any country
              or jurisdiction where such distribution or use would be contrary
              to local law or regulation.
            </p>
            <p class="text-secondary text-justify" style="font-size: 10px">
              Reminder: We have all heard the phrase "high risk, high return"
              when it comes to the financial market. It's important to remember
              that forex and CFDs are leveraged products with a high level of
              risk. It is possible to lose all your capital. Make sure that you
              have fully understood the risks involved and implement proper risk
              management or seek independent advice if necessary.
            </p>
            <p class="text-secondary text-justify" style="font-size: 10px">
              At Prime Codex LLC., we believe in keeping our clients fully
              informed about the risks that come with investing. We want to make
              sure that our clients are familiar with the rules and regulations
              that apply to investment products, including the possibility of
              changes in underlying assets due to corporate events. Investing
              comes with unique risks and features to consider, such as sudden
              changes in prices, high volatility, and low liquidity.
            </p>
            <p class="text-secondary text-justify" style="font-size: 10px">
              Therefore, to ensure that you're fully equipped to navigate the
              world of investing, we suggest you take the time to thoroughly
              review our user agreement and familiarize yourself with the basic
              principles and rules of the financial markets.
            </p>
            <p class="text-secondary text-justify" style="font-size: 10px">
              While we strive to provide a reliable and secure platform for
              trading, there may be situations beyond our control, such as
              government regulations, changes in foreign exchange or stock
              markets, military actions, and unexpected events that could impact
              trading. In such cases, Prime Codex LLC cannot guarantee or be
              held responsible for any potential damages or losses.
            </p>
            <p class="text-secondary text-justify" style="font-size: 10px">
              By accessing any page on our Site, you agree to comply with the
              terms and conditions contained herein. If you do not agree with
              the Terms, please do not access or use our Site. primecodex.com
              reserves the right to modify or update the Terms, at its sole
              discretion, without notice.It is your responsibility to review the
              Terms regularly to ensure that your usage of the Site and services
              are always in line with the Terms and Conditions. Please read the
              terms of use carefully.
            </p>
          </div>
        </div>
      </div>
    </div>
    <p class="py-3 text-secondary text-center" style="font-size: 10px">
      © 2023 PrimeCodex. All rights reserved
    </p>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
