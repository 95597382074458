export default {
  auth: {
    title: `Login Account`,
    password: `Kata Sandi`,
    login: {
      title: `Masuk`,
      helper: `Masukan email dan password anda`,
      forgot_password: `Kata Sandi`,
      dont_have_an_account_yet: `Belum punya akun?`,
    },
    register: {
      title: `Daftar`,
    },
  },
  global: {
    or: `atau`,
  },
}
