import { ref } from "vue"
import * as echarts from "echarts"

export const lineChart = (data: any, isProfit: true) => {
  const dataX = data && data.dataX ? data.dataX : []
  const dataY = data && data.dataY ? data.dataY : []
  const option = ref({
    xAxis: {
      data: dataX,
      show: false,
    },
    yAxis: {
      show: false,
      axisLine: {
        lineStyle: {
          width: 3,
        },
      },
    },
    tooltip: {
      show: false,
    },
    grid: {
      left: 0,
      right: 0,
    },
    series: [
      {
        type: `line`,
        smooth: true,
        lineStyle: {
          width: 2,
          color: isProfit ? `rgba(6,166,60,1)` : `rgba(249, 66, 0,1)`,
        },
        symbol: `none`,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: isProfit ? `rgba(6,172,62,1)` : `rgba(249, 66, 0,0)`,
            },
            {
              offset: 1,
              color: isProfit ? `rgba(6,170,60,0)` : `rgba(249, 66, 0,1)`,
            },
          ]),
        },

        data: dataY,
      },
    ],
  })
  return { option }
}
