import { createI18n } from "vue-i18n"
import messages from "@/i18n"
// const lang = localStorage.getItem(`lang`) === `en-us` ? `en` : `id`
const lang = `en`
export const i18n = createI18n({
  locale: lang || `en`,
  fallbackLocale: lang || `en`,
  globalInjection: true,
  messages: messages,
})

export default i18n
