<template>
  <div id="money-manager-filter-bar">
    <div class="money-manager-filter">
      <div class="filter">
        <div class="sort-filter">
          <p class="text-nowrap">Sort by:</p>
          <select
            v-model="sortBy"
            name="sort-by"
            id="select-input"
            @change="onSortBy($event)"
          >
            <template v-for="sort in listSortBy" :key="sort.value">
              <option
                v-if="investment && sort.value === 'name' ? false : true"
                :value="sort.value"
              >
                {{ sort.label }}
              </option>
            </template>
          </select>
        </div>
        <div>
          <n-button size="tiny" circle color="#00CF47" @click="onSortAsc">
            <template #icon>
              <n-icon>
                <i
                  class="fa-solid"
                  :class="sortAsc ? 'fa-arrow-down' : 'fa-arrow-up'"
                ></i>
              </n-icon>
            </template>
          </n-button>
        </div>

        <!-- <div class="divider"></div>
        <div class="hide-mm">
          <input type="checkbox" name="hide-mm" id="hide-mm" />
          <p>Hide MM with Negative Profit</p>
        </div> -->
        <div v-if="!investment" class="divider last"></div>
      </div>
      <n-input
        v-if="!investment"
        v-model:value="search"
        round
        size="medium"
        style="
          --n-color: none;
          --n-color-focus: none;
          --n-border: 1px solid white;
          --n-border-focus: 1.5px solid white;
          --n-text-color: white;
          --n-caret-color: white;
        "
        placeholder="Search Manager..."
        @change.enter="onSearch"
      >
        <template #suffix>
          <n-icon color="white">
            <i class="fa-solid fa-magnifying-glass"></i>
          </n-icon>
        </template>
      </n-input>
    </div>
    <div v-if="investment" class="invest">
      <font-awesome-icon class="icon" :icon="['fas', 'dollar-sign']" />
      <p>{{ countInvestment }} / 5 Investment</p>
    </div>
    <div class="display-as">
      <p>Display As</p>
      <div class="divider"></div>
      <div class="display-as-toggle">
        <div
          class="display-toggle"
          :class="{ active: gridView }"
          role="button"
          @click="setGrid('grid')"
        >
          <img src="/assets/img/show-grid-icon.svg" alt="show-grid" />
        </div>
        <div
          class="display-toggle"
          :class="{ active: !gridView }"
          @click="setGrid('table')"
        >
          <img src="/assets/img/show-table-icon.svg" alt="show-table" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NSelect, NButton, NIcon, NInput } from "naive-ui";
import { ref, watch } from "vue";
const emit = defineEmits(["toggleView", "onFilters"]);

type listSortBy = {
  label: string;
  value: string;
};

type filters = {
  sort_by: string;
  sort_asc: boolean;
  search: string;
  non_negative_profit?: boolean;
};

interface IProps {
  gridView: boolean;
  investment?: boolean;
  countInvestment?: number;
  listSortBy: listSortBy[];
  filters: filters;
}

const props = defineProps<IProps>();
const sortBy = ref("");
const sortAsc = ref();
const search = ref("");

watch(props, (val) => {
  sortBy.value = val.filters.sort_by;
  sortAsc.value = val.filters.sort_asc;
  search.value = val.filters.search;
});

function setGrid(target) {
  emit("toggleView", target === "grid" ? true : false);
}

function onSortAsc() {
  sortAsc.value = !sortAsc.value;
  onSearch();
}

function onSortBy(event) {
  sortBy.value = event.target.value;
  onSearch();
}

function onSearch() {
  const filter = {
    sort_by: sortBy.value,
    sort_asc: sortAsc.value,
    search: search.value,
  };
  emit("onFilters", filter);
}
</script>
