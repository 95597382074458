import Swal from "sweetalert2"

type NotificationConfig = {
  title?: string
  showCancelButton?: boolean
  showConfirmButton?: boolean
  showDenyButton?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  timer?: number
  confirmButtonColor?: string
  cancelButtonColor?: string
}

export const notificationSuccess = (
  message: string,
  config?: NotificationConfig,
  callback = () => {
    //
  },
) => {
  const timer = config?.timer ? { timer: config.timer } : {}
  Swal.fire({
    ...timer,
    icon: `success`,
    text: message,
    title: config?.title || `Success!`,
    showConfirmButton: config?.showConfirmButton
      ? config?.showConfirmButton
      : true,
    showCancelButton: config?.showCancelButton || false,
    showDenyButton: config?.showDenyButton || false,
    confirmButtonText: config?.confirmButtonText || `Ok`,
    cancelButtonText: config?.cancelButtonText || `Cancel`,
    confirmButtonColor: config?.confirmButtonColor || `#01c906`,
    cancelButtonColor: config?.cancelButtonColor || `#f16d7e`,
  }).then(() => {
    callback()
  })
}

export const notificationWarning = (
  error: any,
  config?: NotificationConfig,
  callback = () => {
    //
  },
) => {
  const message = error?.response?.data?.message
    ? error.response.data.message
    : error?.message === `Network Error`
      ? `Please try to reload your browser. If the error persist, please contact our admin for support`
      : error

  Swal.fire({
    icon: `warning`,
    title: config?.title || `Success!`,
    text: message,
    showCancelButton: config?.showCancelButton || false,
    showDenyButton: config?.showDenyButton || false,
    confirmButtonText: config?.confirmButtonText || `Ok`,
    cancelButtonText: config?.cancelButtonText || `Cancel`,
    confirmButtonColor: config?.confirmButtonColor || `#01c906`,
    cancelButtonColor: config?.cancelButtonColor || `#f16d7e`,
  }).then((result) => {
    if (result.value) {
      callback()
    }
  })
}

export const notificationError = (error: any, config?: NotificationConfig) => {
  const message = error?.response?.data?.error?.message
    ? error?.response?.data?.error?.message
    : error?.response?.data?.message
      ? error.response.data.message
      : error?.message === `Network Error`
        ? `Please try to reload your browser. If the error persist, please contact our admin for support`
        : error

  Swal.fire({
    icon: `warning`,
    title: config?.title || `Connection Error!`,
    text: message,
    showCancelButton: config?.showCancelButton || false,
    showDenyButton: config?.showDenyButton || false,
    confirmButtonText: config?.confirmButtonText || `Ok`,
    cancelButtonText: config?.cancelButtonText || `Cancel`,
    confirmButtonColor: config?.confirmButtonColor || `#01c906`,
    cancelButtonColor: config?.cancelButtonColor || `#f16d7e`,
  })
}

export const notificationQuestion = (
  message: string,
  config?: NotificationConfig,
  callback = () => {
    //
  },
) => {
  const timer = config?.timer ? { timer: config.timer } : {}
  Swal.fire({
    ...timer,
    icon: `question`,
    text: message,
    title: config?.title || `Incomplete Data!`,
    showConfirmButton: config?.showConfirmButton
      ? config?.showConfirmButton
      : true,
    showCancelButton: config?.showCancelButton || false,
    showDenyButton: config?.showDenyButton || false,
    confirmButtonText: config?.confirmButtonText || `Ok`,
    cancelButtonText: config?.cancelButtonText || `Cancel`,
    confirmButtonColor: config?.confirmButtonColor || `#01c906`,
    cancelButtonColor: config?.cancelButtonColor || `#f16d7e`,
  }).then(() => {
    callback()
  })
}
