<template>
  <div @click="toLink(data)" class="investor-card h-100 p-2">
    <div class="row p-2">
      <div class="col-12 d-flex">
        <img
          :src="
            data.persona && data.persona.profile_pic_url
              ? data.persona.profile_pic_url
              : '/assets/img/avatar.svg'
          "
          class="rounded-circle bg-secondary mr-3"
          :alt="data?.manager_detail?.Name"
          style="width: 40px; height: 40px"
        />
        <div>
          <h6 class="font-weight-bold" style="margin-bottom: 0">
            {{ data?.manager_detail?.Name || "-" }}
          </h6>
          <small class="text-muted"
            >ID : {{ data?.manager_detail?.Id || "-" }}
          </small>
          <span v-if="data.statusInvestment">
            <n-tag
              class="ml-2"
              round
              size="small"
              :bordered="false"
              :type="
                data.statusInvestment === 'connected'
                  ? 'success'
                  : data.status === 'disconnected'
                  ? 'error'
                  : data.status === 'pending'
                  ? 'warning'
                  : 'default'
              "
            >
              {{ data.statusInvestment }}
            </n-tag>
            <n-popover
              v-if="
                data.statusInvestment === `pending-disconnect` ||
                data.statusInvestment === `pending`
              "
              trigger="hover"
              placement="right"
            >
              <template #trigger>
                <n-icon class="ml-2">
                  <i class="fa-solid fa-circle-info"></i>
                </n-icon>
              </template>
              <span>please wait for money manager approval</span>
            </n-popover>
          </span>
        </div>
      </div>

      <div class="col-12 text-right">
        <small class="text-secondary">Profit</small>
        <p
          :class="
            data?.manager_detail?.TotalProfit < 0
              ? 'text-danger'
              : 'text-success'
          "
          class="mb-0 txt-bold"
        >
          <img
            :src="
              data?.manager_detail?.TotalProfit < 0
                ? `/assets/img/trending_down.svg`
                : '/assets/img/trending_up.svg'
            "
            alt="trending"
          />
          {{ data?.manager_detail?.TotalProfit }} USD
        </p>
      </div>
      <div class="col-12">
        <ChartCustome
          type="lineChart"
          :data="data.performanceHistory"
          :is-profit="data?.manager_detail?.TotalProfit >= 0"
        />
      </div>
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-4 text-center">
            <small class="mb-0 txt-bold">
              {{
                normalizeFloat(countJoinFee(data), {
                  toFixed: 2,
                })
              }}
              % </small
            ><br />
            <small class="text-secondary">Join Fee</small>
          </div>
          <div
            style="
              border-color: #e4eaf0;
              border-style: solid;
              border-width: 0 1px;
            "
            class="col-4 text-center"
          >
            <small class="mb-0 txt-bold">
              {{
                normalizeFloat(countPerformanceFee(data), {
                  toFixed: 2,
                })
              }}
              % </small
            ><br />
            <small class="text-secondary">Performance Fee</small>
          </div>
          <div class="col-4 text-center">
            <small class="mb-0 txt-bold">
              {{
                data?.penalty_setting?.period_setting?.minimum_day_invest || 0
              }}
              Days </small
            ><br />
            <small class="text-secondary">Trading Period</small>
          </div>
        </div>
      </div>

      <small class="col-12 text-center text-secondary p-2">
        Minimum Investment Start From
        <b class="text-success"
          >{{
            normalizeFloat(data?.manager_detail?.MinInvestment || 0, {
              toFixed: 2,
            })
          }}
          USD</b
        >
      </small>
    </div>
  </div>
  <AlertPopup :is-open="isOpen" @close="isOpen = $event"></AlertPopup>
</template>
<script setup lang="ts">
import { ref } from "vue";
import AlertPopup from "../popup/AlertPopup.vue";
import ChartCustome from "../chart/ChartCustome.vue";
import { normalizeFloat } from "@turnkeyid/utils-ts/web";
import { NTag, NTooltip, NIcon, NPopover } from "naive-ui";
import { useRouter } from "vue-router";

const isOpen = ref(false);
const router = useRouter();
type IProps = {
  data?: any;
  type?: string;
};
const props = defineProps<IProps>();

function countJoinFee(data) {
  if (data.manager_detail && data.manager_detail.IncentiveAccounts) {
    const initialValue = 0;
    const joinFee = data.manager_detail.IncentiveAccounts;

    const sumWithInitial = joinFee.reduce(
      (accumulator, currentValue) => accumulator + currentValue.ManagementFee,
      initialValue
    );
    return sumWithInitial;
  }
  return 0;
}

function countPerformanceFee(data) {
  if (data.manager_detail && data.manager_detail.IncentiveAccounts) {
    const initialValue = 0;
    const joinFee = data.manager_detail.IncentiveAccounts;

    const sumWithInitial = joinFee.reduce(
      (accumulator, currentValue) => accumulator + currentValue.IncentiveFee,
      initialValue
    );
    return sumWithInitial;
  }
  return 0;
}

function toLink(data) {
  if (!data.isUserInvestment) {
    router.push(
      `/pamm/investor-account/money-managers/${data.id ? data.id : data._id}`
    );
  } else {
    if (
      data.statusInvestment === `pending` ||
      data.statusInvestment === `pending-disconnect`
    ) {
      router.push(
        `/pamm/investor-account/money-managers/${data.id ? data.id : data._id}`
      );
      return;
    }
    router.push(
      `/pamm/investor-account/my-investment/${data.idUserInvestment}`
    );
  }
}
</script>
