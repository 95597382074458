<template>
  <n-modal
    v-model:show="show"
    preset="dialog"
    :type="type"
    :title="title"
    :positive-text="positiveText"
    :negative-text="negativeText"
    @mask-click="onCancel"
    @close="onCancel"
    @positive-click="onConfirm"
    @negative-click="onCancel"
  >
    <div v-html="message" />
  </n-modal>
</template>

<script setup lang="ts">
import { NModal, NCard } from "naive-ui";
import { ref, watch } from "vue";

const emit = defineEmits(["onCancel", "onConfirm"]);
interface IProps {
  isOpen: boolean;
  title: string;
  message: string;
  positiveText?: string;
  negativeText?: string;
  type?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  isOpen: false,
  positiveText: "Confirm",
  type: "success",
});

const show = ref(props.isOpen);

watch(props, (val) => {
  show.value = val.isOpen;
});

function onConfirm() {
  show.value = false;
  emit("onConfirm", show.value);
}

function onCancel() {
  show.value = false;
  emit("onCancel", show.value);
}
</script>
