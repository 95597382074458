<template>
  <table :bordered="false" style="width: 1060px" class="table fixed-column-1">
    <colgroup>
      <!-- <col style="width: 50px" /> -->
      <col :style="screenSizes.xs ? 'width: 110px' : 'width: 320px'" />
      <col style="width: 150px" />
      <col style="width: 320px" />
      <col style="width: 320px" />
      <col style="width: 150px" />
    </colgroup>
    <thead>
      <tr class="header">
        <!-- <th>No</th> -->
        <th class="text-nowrap">Managers</th>
        <!-- <th>Volume</th> -->
        <th class="text-nowrap">Profit</th>
        <th class="text-nowrap">Min Investment</th>
        <th class="text-nowrap">Manager Fee</th>
        <th class="text-nowrap">Overview</th>
      </tr>
    </thead>
    <tr v-for="(data, index) in pammManager" v-bind:key="index">
      <!-- <td>{{ index + 1 }}</td> -->
      <td>
        <div class="d-flex pointer" @click="toLink(data)">
          <n-avatar
            round
            size="large"
            :src="data?.persona?.profile_pic_url || '/assets/img/avatar.svg'"
            :alt="data?.manager_detail?.Name"
            class="mr-2"
          />
          <div>
            <h6
              class="font-weight-bold mb-0 text-ellipsis"
              :style="screenSizes.xs ? 'width: 110px' : 'width: 320px'"
            >
              {{ data?.manager_detail?.Name || "-" }}
            </h6>
            <small class="text-muted">
              ID :
              {{ data?.manager_detail?.Id || "-" }}
            </small>
            <span v-if="data.statusInvestment">
              <n-tag
                class="ml-2"
                round
                size="small"
                :bordered="false"
                :type="
                  data.statusInvestment === 'connected'
                    ? 'success'
                    : data.status === 'disconnected'
                    ? 'error'
                    : data.status === 'pending'
                    ? 'warning'
                    : 'default'
                "
              >
                {{ data.statusInvestment }}
              </n-tag>
              <n-popover
                v-if="
                  data.statusInvestment === `pending-disconnect` ||
                  data.statusInvestment === `pending`
                "
                trigger="hover"
                placement="right"
              >
                <template #trigger>
                  <n-icon class="ml-2">
                    <i class="fa-solid fa-circle-info"></i>
                  </n-icon>
                </template>
                <span>please wait for money manager approval</span>
              </n-popover>
            </span>
          </div>
        </div>
      </td>
      <!-- <td>
        {{
          normalizeFloat(data.summary_trade?.total_transaction_vol || 0, {
            toFixed: 2,
          })
        }}
        Lot
      </td> -->
      <td :class="data?.manager_detail?.TotalProfit < 0 ? 'red' : 'green'">
        {{
          normalizeFloat(data?.manager_detail?.TotalProfit || 0, {
            toFixed: 2,
          })
        }}
        %
      </td>
      <td>
        <p>
          {{
            normalizeFloat(data?.manager_detail?.MinInvestment || 0, {
              toFixed: 2,
            })
          }}
          USD
        </p>
      </td>
      <td>
        <div class="manager-fee">
          <p>
            <span>Trading Period :</span>
            {{ data?.penalty_setting?.period_setting?.minimum_day_invest || 0 }}
            Days
          </p>
          <p>
            <span>Join Fee :</span>
            {{
              normalizeFloat(countJoinFee(data), {
                toFixed: 2,
              })
            }}
            %
          </p>
          <p>
            <span>Performance Fee :</span>
            {{
              normalizeFloat(countPerformanceFee(data), {
                toFixed: 2,
              })
            }}
            %
          </p>
        </div>
      </td>
      <td>
        <div class="chart-container">
          <ChartCustome
            type="lineChart"
            :data="data.performanceHistory"
            :isProfit="data?.manager_detail?.TotalProfit >= 0"
          />
        </div>
      </td>
    </tr>
  </table>
  <AlertPopup :is-open="isOpen" @close="isOpen = $event"></AlertPopup>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AlertPopup from "../popup/AlertPopup.vue";
import ChartCustome from "../chart/ChartCustome.vue";
import { useRouter } from "vue-router";
import { normalizeFloat } from "@turnkeyid/utils-ts/web";
import { screenSizes } from "@/helper/index";
import {
  NAvatar,
  NButton,
  NIcon,
  NPopover,
  NTag,
  NTooltip,
  NTable,
} from "naive-ui";
const isOpen = ref(false);
const router = useRouter();
interface IProps {
  pammManager: any;
}
const props = defineProps<IProps>();

function countJoinFee(data) {
  if (data.manager_detail && data.manager_detail.IncentiveAccounts) {
    const initialValue = 0;
    const joinFee = data.manager_detail.IncentiveAccounts;

    const sumWithInitial = joinFee.reduce(
      (accumulator, currentValue) => accumulator + currentValue.ManagementFee,
      initialValue
    );
    return sumWithInitial;
  }
  return 0;
}

function countPerformanceFee(data) {
  if (data.manager_detail && data.manager_detail.IncentiveAccounts) {
    const initialValue = 0;
    const joinFee = data.manager_detail.IncentiveAccounts;

    const sumWithInitial = joinFee.reduce(
      (accumulator, currentValue) => accumulator + currentValue.IncentiveFee,
      initialValue
    );
    return sumWithInitial;
  }
  return 0;
}

function toLink(data) {
  if (!data.isUserInvestment) {
    router.push(
      `/pamm/investor-account/money-managers/${data.id ? data.id : data._id}`
    );
  } else {
    if (
      data.statusInvestment === `pending` ||
      data.statusInvestment === `pending-disconnect`
    ) {
      router.push(
        `/pamm/investor-account/money-managers/${data.id ? data.id : data._id}`
      );
      return;
    }

    router.push(
      `/pamm/investor-account/my-investment/${data.idUserInvestment}`
    );
  }
}
</script>
