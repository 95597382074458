<template>
  <n-modal
    v-model:show="show"
    preset="dialog"
    class="custom-modal"
    type="warning"
    :show-icon="false"
    title="This Investment Still Waiting Approval"
    positive-text="Confirm"
    negative-text=""
    @mask-click="onClose"
    @close="onClose"
    @positive-click="onClose"
    @negative-click="onClose"
  >
    <div>
      <p class="text-left">
        Status <b>Waiting Approval</b> can’t view my invetsment detail
      </p>
    </div>
  </n-modal>
</template>

<script setup lang="ts">
const emit = defineEmits(["close"]);
import { NModal } from "naive-ui";
import { ref, watch } from "vue";

interface IProps {
  isOpen: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  isOpen: false,
});
const show = ref(props.isOpen);
watch(props, (val) => {
  show.value = val.isOpen;
});
function onClose() {
  emit(`close`, !props.isOpen);
}
</script>
