<template>
  <table :bordered="false" style="width: 1200px" class="table fixed-column-1">
    <colgroup>
      <!-- <col style="width: 50px" /> -->
      <col :style="screenSizes.xs ? 'width: 110px' : 'width: 320px'" />
      <col style="width: 200px" />
      <col style="width: 150px" />
      <col style="width: 150px" />
      <col style="width: 180px" />
      <col style="width: 150px" />
      <col style="width: 150px" />
    </colgroup>
    <thead>
      <tr class="header">
        <!-- <th>No</th> -->
        <th class="text-nowrap">Managers</th>
        <th class="text-nowrap">Status</th>
        <th class="text-nowrap">Growth</th>
        <th class="text-nowrap">Total Deposit</th>
        <th class="text-nowrap">Total Profit</th>
        <th class="text-nowrap">Total Withdraw</th>
        <th class="text-nowrap">Equity</th>
        <th class="text-nowrap">Disconnect Equity</th>
        <th class="text-nowrap">Overview</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in userInvestment" v-bind:key="index">
        <!-- <td>{{ index + 1 }}</td> -->
        <td>
          <div class="d-flex pointer" @click="toLink(data)">
            <n-avatar
              round
              size="large"
              :src="
                data?.manager?.persona?.profile_pic_url ||
                '/assets/img/avatar.svg'
              "
              placeholder="/assets/img/avatar.svg"
              :alt="data?.manager?.manager_detail?.Name || '-'"
              class="mr-2"
            />
            <div>
              <h6
                class="font-weight-bold mb-0 text-ellipsis"
                :style="screenSizes.xs ? 'width: 110px' : 'width: 320px'"
              >
                {{ data?.manager?.manager_detail?.Name || "-" }}
              </h6>
              <small class="text-muted">
                ID :
                {{ data?.manager?.manager_detail?.Id || "-" }}
              </small>
            </div>
          </div>
        </td>
        <td>
          <div>
            <n-tag
              round
              size="small"
              :bordered="false"
              :type="
                data.status === 'connected'
                  ? 'success'
                  : data.status === 'disconnected'
                  ? 'error'
                  : data.status === 'pending'
                  ? 'warning'
                  : 'default'
              "
            >
              {{ data.status }}
            </n-tag>
            <n-popover
              v-if="
                data.status === `pending-disconnect` ||
                data.status === `pending`
              "
              trigger="hover"
              placement="right"
            >
              <template #trigger>
                <n-icon class="ml-2">
                  <i class="fa-solid fa-circle-info"></i>
                </n-icon>
              </template>
              <span>please wait for money manager approval</span>
            </n-popover>
          </div>
        </td>
        <td :class="countGrowth(data) >= 0 ? 'green' : 'text-danger'">
          {{ countGrowth(data) }}%
        </td>
        <td>
          {{
            normalizeFloat(data?.account?.investor_detail?.Balance || 0, {
              toFixed: 2,
            })
          }}
          USD
        </td>
        <td
          :class="
            data?.account?.investor_detail?.TotalProfit >= 0
              ? 'green'
              : 'text-danger'
          "
        >
          {{
            normalizeFloat(data?.account?.investor_detail?.TotalProfit || 0, {
              toFixed: 2,
            })
          }}
          USD
        </td>
        <td
          :class="
            data?.invest_summary?.total_disconnect +
              data?.invest_summary?.total_withdraw >=
            0
              ? 'text-success'
              : 'text-danger'
          "
        >
          {{
            normalizeFloat(
              data?.invest_summary?.total_disconnect +
                data?.invest_summary?.total_withdraw || 0,
              {
                toFixed: 2,
              }
            )
          }}
          USD
        </td>
        <td>
          {{
            normalizeFloat(data?.account?.investor_detail?.Equity || 0, {
              toFixed: 2,
            })
          }}
          USD
        </td>
        <td>
          {{
            normalizeFloat(
              data?.account?.investor_detail?.DisconnectEquity || 0,
              {
                toFixed: 2,
              }
            )
          }}
          USD
        </td>
        <td>
          <div class="chart-container">
            <ChartCustome
              type="lineChart"
              :data="data.performanceHistory"
              :isProfit="data?.account?.investor_detail?.TotalProfit >= 0"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <AlertPopup :is-open="isOpen" @close="isOpen = $event"></AlertPopup>
</template>

<script setup lang="ts">
import ChartCustome from "../chart/ChartCustome.vue";
import AlertPopup from "../popup/AlertPopup.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { normalizeFloat } from "@turnkeyid/utils-ts/web";
import { screenSizes } from "@/helper/index";
import { NAvatar, NIcon, NPopover, NTag, NTooltip, NTable } from "naive-ui";
const router = useRouter();
interface IProps {
  userInvestment: any;
}
const isOpen = ref(false);

const props = defineProps<IProps>();

function toLink(data) {
  if (data.status === `pending`) {
    isOpen.value = true;
    return;
  }
  if (data.status === `pending-disconnect`) {
    return;
  }
  router.push(
    `/pamm/investor-account/my-investment/${data.id ? data.id : data._id}`
  );
}
function countGrowth(data) {
  const balance =
    data.account &&
    data.account.investor_detail &&
    data.account.investor_detail.Balance
      ? data.account.investor_detail.Balance
      : 0;
  const totalProfit =
    data.account &&
    data.account.investor_detail &&
    data.account.investor_detail.TotalProfit
      ? data.account.investor_detail.TotalProfit
      : 0;
  if (balance && totalProfit) {
    return normalizeFloat((totalProfit / balance) * 100, { toFixed: 2 });
  }
  return 0;
}
</script>
