<template>
  <div>
    <div id="sidebar-desktop" :class="{ active: active }">
      <div class="sidebar-container">
        <router-link to="/dashboard">
          <img src="/assets/img/logo-only.png" alt="" class="logo" />
          <span :class="{ active: active }" class="text-white ml-3 brand-text"
            ><span class="fs-4 text-white">Prime</span
            ><span class="font-weight-lighter fs-4 text-white"
              >Codex</span
            ></span
          >
        </router-link>

        <div class="user-icon" style="align-items: center">
          <router-link to="/profile">
            <div class="user py-2 text-center text-dark txt-bold">
              {{ getInitialName(user.name || "") }}
            </div>
          </router-link>
          <div class="user-data" style="text-align: center; padding-top: 5px">
            <router-link to="/profile">
              <h4 class="text-ellipsis text-wrap">
                {{ user.name ?? "" }}
              </h4>
            </router-link>
            <p>{{ pascalString(user.role) ?? "" }}</p>
            <p class="text-ellipsis">{{ user.email ?? "" }}</p>
            <p>{{ user.phone ?? "" }}</p>
          </div>
        </div>

        <div class="menu-list m-0">
          <!-- <span class="small text-white">Show Menu</span> -->
          <div class="sidebar-toggle-switch">
            <div class="toggle" @click="handleExpand">
              <div class="switch" :class="{ active: active }"></div>
            </div>
          </div>
          <div class="divider"></div>
          <template v-for="menu in listMenu">
            <div v-if="!menu.subMenu" :key="menu.to">
              <router-link :to="menu.to">
                <div
                  class="menu"
                  role="button"
                  :class="{ active: menu.isActive }"
                >
                  <div class="menu-content">
                    <img v-if="menu.isActive == true" :src="menu.iconActive" />
                    <img v-else :src="menu.icon" />
                    <h4>{{ menu.label }}</h4>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-else :key="menu.to">
              <div
                class="menu"
                :class="{ active: menu.isActive }"
                role="button"
              >
                <a href="javascript:void(0)" @click="onActiveMenu(menu.name)">
                  <div class="menu-content">
                    <img v-if="menu.isActive" :src="menu.iconActive" />
                    <img v-else :src="menu.icon" />
                    <h4>{{ menu.label }}</h4>
                  </div>
                </a>
              </div>
              <div
                class="submenu"
                :class="{ active: menu.isActive }"
                role="button"
              >
                <template v-for="submenu in menu.subMenu" :key="submenu.to">
                  <template v-if="submenu.isShow != false">
                    <div
                      class="submenu-content pointer"
                      :class="{ active: menu.isActive }"
                      v-if="submenu.isComingSoon"
                      @click="popUpComingSoon"
                    >
                      <div class="selector"></div>
                      <div class="v-selector"></div>
                      <img :src="submenu.icon" />
                      <h4>{{ submenu.label }}</h4>
                    </div>
                    <router-link
                      v-else
                      :to="submenu.to"
                      class="submenu-content"
                      :class="{ active: menu.isActive }"
                    >
                      <div class="selector"></div>
                      <div class="v-selector"></div>
                      <img :src="submenu.icon" />
                      <h4>{{ submenu.label }}</h4>
                    </router-link>
                  </template>
                </template>
              </div>
            </div>
          </template>
          <div class="divider"></div>
        </div>
        <!-- <div class="user-icon">
          <router-link to="/profile">
            <div class="user py-2 text-center text-dark txt-bold">
              {{ getInitialName(user.name || "") }}
            </div>
          </router-link>
          <div class="user-data">
            <router-link to="/profile">
              <h4>{{ user.name ?? "" }}</h4>
            </router-link>
            <p>{{ pascalString(user.role) ?? "" }}</p>
            <p class="text-ellipsis">{{ user.email ?? "" }}</p>
            <p>{{ user.phone ?? "" }}</p>
          </div>
        </div> -->
        <div class="logout-wrapper d-flex btn-wrapper logout-wrapper mt-3">
          <button
            class="logout-btn p-2 px-3 border rounded text-dark bg-white"
            @click="handleLogout"
            role="button"
          >
            <font-awesome-icon
              :icon="['fas', 'fa-right-from-bracket']"
              class="text-danger"
            />
            <h4 class="d-inline-block fs-6 text-dark txt-bold">Logout</h4>
          </button>
        </div>
      </div>
    </div>
    <div id="sidebar-mobile">
      <div class="sidebar-container">
        <img src="/assets/img/logo-only.png" alt="" class="logo" />
        <span class="text-white ml-3"
          ><span class="fs-4 text-white">Prime</span
          ><span class="font-weight-lighter fs-4 text-white">Codex</span></span
        >
        <div
          class="hamburger"
          :class="{ active: active }"
          @click="toggleSidebarMobileActive"
          role="button"
        >
          <div class="bar"></div>
          <div class="bar center"></div>
          <div class="bar"></div>
        </div>
        <div class="menu-container" :class="{ active: active }">
          <div class="user-icon" style="align-items: center">
            <router-link to="/profile">
              <div class="user py-2 text-center text-dark txt-bold">
                {{ getInitialName(user.name || "") }}
              </div>
            </router-link>
            <div class="user-data" style="text-align: center; padding-top: 5px">
              <router-link to="/profile">
                <h4 class="text-ellipsis">{{ user.name ?? "" }}</h4>
              </router-link>
              <p>{{ pascalString(user.role) ?? "" }}</p>
              <p class="text-ellipsis">{{ user.email ?? "" }}</p>
              <p>{{ user.phone ?? "" }}</p>
            </div>
          </div>
          <div class="menu-list">
            <template v-for="menu in listMenu">
              <div v-if="!menu.subMenu" :key="menu.to">
                <router-link :to="menu.to">
                  <div
                    class="menu"
                    role="button"
                    :class="{ active: menu.isActive }"
                  >
                    <div class="menu-content">
                      <img v-if="menu.isActive" :src="menu.iconActive" />
                      <img v-else :src="menu.icon" />
                      <h4>{{ menu.label }}</h4>
                    </div>
                  </div>
                </router-link>
              </div>
              <div v-else :key="menu.to">
                <div
                  class="menu"
                  :class="{ active: menu.isActive }"
                  role="button"
                >
                  <a href="javascript:void(0)" @click="onActiveMenu(menu.name)">
                    <div class="menu-content">
                      <img v-if="menu.isActive" :src="menu.iconActive" />
                      <img v-else :src="menu.icon" />
                      <h4>{{ menu.label }}</h4>
                    </div>
                  </a>
                </div>
                <div
                  class="submenu"
                  :class="{ active: menu.isActive }"
                  role="button"
                >
                  <template v-for="submenu in menu.subMenu" :key="submenu.to">
                    <template v-if="submenu.isShow != false">
                      <div
                        class="submenu-content pointer"
                        :class="{ active: menu.isActive }"
                        v-if="submenu.isComingSoon"
                        @click="popUpComingSoon"
                      >
                        <div class="selector"></div>
                        <div class="v-selector"></div>
                        <img :src="submenu.icon" />
                        <h4>{{ submenu.label }}</h4>
                      </div>
                      <router-link
                        v-else
                        :to="submenu.to"
                        class="submenu-content"
                        :class="{ active: menu.isActive }"
                      >
                        <div class="selector"></div>
                        <div class="v-selector"></div>
                        <img :src="submenu.icon" />
                        <h4>{{ submenu.label }}</h4>
                      </router-link>
                    </template>
                  </template>
                </div>
              </div>
            </template>

            <div class="divider"></div>
          </div>
          <div class="user-icon">
            <!-- <router-link to="/profile">
              <div class="user text-dark txt-bold py-2 text-center">
                {{ getInitialName(user.name || "") }}
              </div>
            </router-link> -->
            <div class="user-data">
              <!-- <router-link to="/profile">
                <h4>{{ user.name ?? "" }}</h4>
              </router-link>
              <p>{{ pascalString(user.role) ?? "" }}</p>
              <p class="text-ellipsis">
                {{ user.email ?? "" }}
              </p>
              <p>{{ user.phone ?? "" }}</p> -->
              <button
                class="logout-btn p-2 px-3 border rounded text-dark bg-white"
                @click="handleLogout"
                role="button"
              >
                <font-awesome-icon
                  :icon="['fas', 'fa-right-from-bracket']"
                  class="text-danger"
                />
                <h4 class="d-inline-block fs-6 ml-1 text-dark txt-bold">
                  Logout
                </h4>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sidebar-container .brand-text {
  display: none;
}
.sidebar-container .brand-text.active {
  display: inline-block;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment-mini";
import { notificationError } from "@/helper/notifications";
import { loading } from "@/helper/loading";

const url = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_API_TOKEN;
export default {
  props: {
    toggleSidebar: { type: Function },
    sidebarMobileActive: {
      type: Boolean,
    },
  },
  data: () => {
    return {
      theme: localStorage.getItem(`theme`),
      listMenu: [
        {
          name: "dashboard",
          to: "/dashboard",
          isActive: true,
          icon: "/assets/img/account.svg",
          iconActive: "/assets/img/accountActive.svg",
          label: "Account",
        },
        {
          name: "deposit",
          to: "/deposit",
          isActive: false,
          icon: "/assets/img/piggy-bank.svg",
          iconActive: "/assets/img/piggy-bankActive.svg",
          label: "Deposit",
        },
        {
          name: "withdraw",
          to: "/withdraw",
          isActive: false,
          icon: "/assets/img/cash-bag.svg",
          iconActive: "/assets/img/cash-bagActive.svg",
          label: "Withdraw",
        },
        {
          name: "internaltransfer",
          to: "/internal-transfer",
          isActive: false,
          icon: "/assets/img/transfer.svg",
          iconActive: "/assets/img/transferActive.svg",
          label: "Transfer",
        },
        {
          name: "partnership",
          to: "/partnership",
          isActive: false,
          icon: "/assets/img/partnership.svg",
          iconActive: "/assets/img/partnershipActive.svg",
          label: "Partnership",
        },
        {
          name: "pamm",
          to: "pamm",
          isActive: false,
          icon: "/assets/img/money.svg",
          iconActive: "/assets/img/moneyActive.svg",
          label: "PAMM",
          subMenu: [
            {
              name: "pamm",
              to: "/pamm/investor-account/money-managers",
              isActive: false,
              icon: "/assets/img/investor-account.svg",
              label: "My Investor Account",
            },
            {
              name: "register-pamm",
              to: "/pamm/money-managers/coming-soon",
              isActive: false,
              icon: "/assets/img/manager-account.svg",
              label: "My Money Manager",
              isShow: true,
            },
          ],
        },
      ],
      user: {},
      active: false,
    };
  },
  watch: {
    $route(val) {
      if (val) {
        this.onActiveMenu(val.name);
        if (this.isMobile() && this.active) {
          this.active = false;
        }
      }
    },
    sidebarMobileActive(val) {
      this.active = val;
    },
  },
  mounted() {
    // !NOTE: FORCE LIGHT MODE, DISABLE IT WHEN DARK MODE IS FIXED
    localStorage.setItem("theme", "dark");
    this.expandOnDesktop();
    this.onActiveMenu(this.$route.name);
    // if (userData) {
    this.getUser();
    // }
  },

  methods: {
    popUpComingSoon() {
      Swal.fire({
        // title: "COMING SOON",
        icon: "warning",
        html: "<h3 class='font-weight-bold'>This Feature is Coming Soon</h3><br><p>If you want to become a money manager, please <b>contact admin</b> Prime Codex</b><br><small><a href='mailto:support@primecodex.com'>support@primecodex.com</a></small> ",
        confirmButtonColor: "#06A63C",
      });
    },
    onActiveMenu(val) {
      if (val) {
        this.listMenu.forEach((item) => {
          if (item.subMenu) {
            item.isActive = item.name === val.toLowerCase();
            item.subMenu.forEach((submenu) => {
              submenu.isActive = item.name === val.toLowerCase();
            });
          } else {
            item.isActive = item.name === val.toLowerCase();
          }
        });
      }
    },
    toggleSidebarMobileActive() {
      this.active = !this.active;
      this.$emit("toggleSidebarMobileActive", this.active);
    },
    handleExpand() {
      this.active = !this.active;
      this.toggleSidebar();
    },
    expandOnDesktop() {
      if (!this.isMobile() && !this.active) {
        this.active = true;
      }
    },
    isMobile() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    },
    pascalString(roleData) {
      return roleData
        ?.toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    getInitialName(name) {
      const words = name.split(" ");
      const initials = words.map((word) => word[0]);
      const result = initials.join("").slice(0, 2);
      return result;
    },
    getUser() {
      const userData = JSON.parse(localStorage.getItem(`userData`));
      loading(true);
      const config = {
        method: `get`,
        url: `${url}/me`,
        headers: {
          "content-type": `application/json`,
          "x-app-token": token,
          "x-user-token": userData?.token ? userData?.token : "",
        },
      };
      axios(config)
        .then((res) => {
          this.user = res.data.data;
        })
        .catch((error) => {
          notificationError(error);
          // remove localstorage
          localStorage.removeItem(`isLogin`);
          localStorage.removeItem(`userData`);
          window.location.href = `/`;
        })
        .finally(() => {
          loading(false);
        });
    },
    handleLogout() {
      if (this.getLang == `indo`) {
        Swal.fire({
          title: `Apakah anda yakin untuk logout?`,
          text: `Anda akan keluar dari halaman ini.`,
          icon: `warning`,
          showCancelButton: true,
          confirmButtonColor: `#3085d6`,
          cancelButtonColor: `#d33`,
          confirmButtonText: `Ya, Keluar sekarang`,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(`Logout Berhasil!`, `Anda berhasil Logout.`, `success`);
            localStorage.clear(`userData`);
            localStorage.setItem(`isLogin`, false);
            window.location.href = `/`;
          }
        });
      } else {
        Swal.fire({
          title: `Are you sure to log out?`,
          text: `You will exit this page.`,
          icon: `warning`,
          showCancelButton: true,
          confirmButtonColor: `#3085d6`,
          cancelButtonColor: `#d33`,
          confirmButtonText: `Yes`,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              `Logout Successful!`,
              `You have successfully logged out.`,
              `success`
            );
            localStorage.clear(`userData`);
            localStorage.setItem(`isLogin`, JSON.stringify({ isLogin: false }));
            window.location.href = `/`;
          }
        });
      }
    },
  },
  updated() {
    if (new Date(localStorage.getItem(`te_now`))) {
      const tokenDate = new Date(localStorage.getItem(`te_now`));
      let isExpired = Math.abs(moment(tokenDate).diff(moment(), "hours")) >= 1;
      if (isExpired) {
        Swal.fire({
          title: "Your session expired!",
          icon: "warning",
          allowOutsideClick: false,
          allowEnterKey: false,
          allowEscapeKey: false,
        }).then(() => {
          window.localStorage.clear();
          window.location.href = "/";
        });
      }
    }
  },
};
</script>
