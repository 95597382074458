<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-loading-bar-provider>
      <n-message-provider>
        <n-notification-provider>
          <n-dialog-provider>
            <div id="app">
              <div
                v-if="VUE_APP_MODE === 'development'"
                class="bg-info text-white px-3 py-1 font-weight-bold text-capitalize fixed-top-right"
              >
                mode: {{ VUE_APP_MODE }}
              </div>
              <router-view></router-view>
            </div>
          </n-dialog-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import {
  NLoadingBarProvider,
  NMessageProvider,
  NNotificationProvider,
  NDialogProvider,
  NConfigProvider,
} from "naive-ui";
const VUE_APP_MODE = process.env.VUE_APP_MODE;

const themeOverrides = {
  common: {
    primaryColor: "#01c906",
    warningColor: "#FFBF1A",
    errorColor: "#F94200",
    borderRadius: "8px",
  },
  Button: {
    fontWeight: "500",
  },
};
</script>
