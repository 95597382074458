<template>
  <div class="my-investment-card h-100 p-2" @click="toLink(data)">
    <div class="row p-2">
      <div class="col-12">Me connected to:</div>
      <div class="col-12 d-flex">
        <img
          :src="profile_pic_url ? profile_pic_url : '/assets/img/avatar.svg'"
          class="rounded-circle bg-secondary mr-3"
          :alt="data?.manager?.manager_detail?.Name"
          style="width: 40px; height: 40px"
        />
        <div>
          <h6 class="font-weight-bold mb-0">
            {{ data?.manager?.manager_detail?.Name }}
          </h6>
          <small class="text-muted mr-1">
            ID : {{ data?.manager?.manager_detail?.Id || "-" }}</small
          >
          <span>
            <n-tag
              class="mt-2"
              round
              size="small"
              :bordered="false"
              :type="
                data.status === 'connected'
                  ? 'success'
                  : data.status === 'disconnected'
                  ? 'error'
                  : data.status === 'pending'
                  ? 'warning'
                  : 'default'
              "
            >
              {{ data.status }}
            </n-tag>

            <n-popover
              v-if="
                data.status === `pending-disconnect` ||
                data.status === `pending`
              "
              trigger="hover"
              placement="right"
            >
              <template #trigger>
                <n-icon class="ml-2">
                  <i class="fa-solid fa-circle-info"></i>
                </n-icon>
              </template>
              <span>please wait for money manager approval</span>
            </n-popover>
          </span>
        </div>
      </div>
      <div class="col-6 text-left">
        <small class="text-secondary">Total Profit</small>
        <p
          :class="investor_detail.TotalProfit >= 0 ? 'profit' : 'text-danger'"
          class="mb-0 txt-bold"
        >
          {{
            normalizeFloat(investor_detail.TotalProfit || 0, {
              toFixed: 2,
            })
          }}
          USD
        </p>
      </div>
      <div class="col-6 text-right">
        <small class="text-secondary">Growth</small>
        <p
          :class="countGrowth(data) >= 0 ? 'profit' : 'text-danger'"
          class="mb-0 txt-bold"
        >
          <img
            :src="
              countGrowth(data) < 0
                ? `/assets/img/trending_down.svg`
                : '/assets/img/trending_up.svg'
            "
            alt="trending"
          />
          {{ countGrowth(data) }}%
        </p>
      </div>
      <div class="col-12">
        <ChartCustome
          type="lineChart"
          :data="data.performanceHistory"
          :is-profit="countGrowth(data) >= 0"
        />
      </div>
      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-4 text-center">
            <small class="mb-0 txt-bold">
              {{
                normalizeFloat(data?.invest_summary?.t4b_total_deposit || 0, {
                  toFixed: 2,
                })
              }}
              USD</small
            ><br />
            <small class="text-secondary">Total Deposit</small>
          </div>
          <div
            style="
              border-color: #e4eaf0;
              border-style: solid;
              border-width: 0 1px;
            "
            class="col-4 text-center"
          >
            <small class="mb-0 txt-bold">
              {{
                normalizeFloat(investor_detail.Equity || 0, {
                  toFixed: 2,
                })
              }}
              USD</small
            ><br />
            <small class="text-secondary">Equity</small>
          </div>
          <div class="col-4 text-center">
            <small
              :class="
                data?.invest_summary?.t4b_total_withdraw >= 0
                  ? 'text-success'
                  : 'text-danger'
              "
              class="mb-0 txt-bold"
            >
              {{
                normalizeFloat(data?.invest_summary?.t4b_total_withdraw || 0, {
                  toFixed: 2,
                })
              }}

              USD </small
            ><br />
            <small class="text-secondary">Total Withdraw</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AlertPopup :is-open="isOpen" @close="isOpen = $event"></AlertPopup>
</template>
<script setup lang="ts">
import { ref } from "vue";
import ChartCustome from "../chart/ChartCustome.vue";
import AlertPopup from "../popup/AlertPopup.vue";
import { useRouter } from "vue-router";
import { NTag, NTooltip, NIcon, NPopover } from "naive-ui";
import { normalizeFloat } from "@turnkeyid/utils-ts/web";

const router = useRouter();
const isOpen = ref(false);

interface IProps {
  data?: any;
  type?: string;
}
const props = defineProps<IProps>();

const profile_pic_url = ref(props.data.manager.persona.profile_pic_url);
const owner = ref(props.data.manager.owner);
const investor_detail = ref(props.data.account.investor_detail);
function toLink(data) {
  if (data.status === `pending`) {
    isOpen.value = true;
    return;
  }
  if (data.status === `pending-disconnect`) {
    return;
  }
  router.push(
    `/pamm/investor-account/my-investment/${data.id ? data.id : data._id}`
  );
}

function countGrowth(data: any): number {
  const balance =
    data.account &&
    data.account.investor_detail &&
    data.account.investor_detail.Balance
      ? data.account.investor_detail.Balance
      : 0;
  const totalProfit =
    data.account &&
    data.account.investor_detail &&
    data.account.investor_detail.TotalProfit
      ? data.account.investor_detail.TotalProfit
      : 0;
  if (balance && totalProfit) {
    return Number(((totalProfit / balance) * 100).toFixed(2));
  }
  return 0;
}
</script>
<style lang="scss">
.investment-card {
  .data {
    display: flex;
    flex-wrap: wrap;
    // row-gap: 12px;
    p {
      flex: 0 0 50%;
      display: flex;
      font-size: 11px;
      font-weight: 600;
      margin-top: 0 !important;
      margin-bottom: 4px;
    }
  }

  .profit {
    color: #06a63c;
  }
  .drawdown {
    color: #ff5e5e;
  }
}
</style>
