import { ref } from "vue"
import * as echarts from "echarts"
export const lineAreaChart = (data: any, isProfit: true) => {
  const dataX = data && data.dataX ? data.dataX : []
  const dataY = data && data.dataY ? data.dataY : []
  const option = ref({
    tooltip: {
      trigger: `axis`,
      axisPointer: {
        type: `cross`,
        label: {
          backgroundColor: `#6a7985`,
        },
      },
    },
    grid: {
      left: `3%`,
      right: `4%`,
      bottom: `3%`,
      containLabel: true,
    },
    dataZoom: [
      {
        type: `inside`,
      },
    ],
    xAxis: [
      {
        type: `category`,
        boundaryGap: false,
        data: dataX,
      },
    ],
    yAxis: [
      {
        type: `value`,
      },
    ],
    series: [
      {
        type: `line`,
        stack: `Total`,
        smooth: true,
        lineStyle: {
          width: 3,
          color: isProfit ? `rgba(6,166,60,1)` : `rgba(249, 66, 0,1)`,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: isProfit ? `rgba(6,172,62,1)` : `rgba(249, 66, 0,0)`,
            },
            {
              offset: 1,
              color: isProfit ? `rgba(6,170,60,0)` : `rgba(249, 66, 0,1)`,
            },
          ]),
        },

        data: dataY,
      },
    ],
  })

  return { option }
}
