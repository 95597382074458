import { createApp } from "vue"
import App from "./App.vue"
import VueSplide from "@splidejs/vue-splide"

import "vue-search-select/dist/VueSearchSelect.css"
import "@splidejs/vue-splide/css"
import "../public/css/light_mode.scss"

import router from "./router/index"
import i18n from "./boot/i18n"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"

library.add(fas, far, fab)

const app = createApp(App)
app.use(VueSplide)
app.use(router)
app.use(i18n)
app.component(`font-awesome-icon`, FontAwesomeIcon)

app.mount(`#app`)
