import Notiflix from "notiflix"

export const loading = (isLoading: boolean) => {
  if (isLoading) {
    Notiflix.Loading.circle(`Loading...`, {
      backgroundColor: `rgba(0,0,0,0.4)`,
      className: `notiflix-loading `,
      svgColor: `#01C906`,
    })
  } else {
    Notiflix.Loading.remove()
  }
}
