<template>
  <v-chart
    :style="
      type === 'lineAreaChart'
        ? `height:${screenSizes.xs ? '400px' : '100%'};min-height:400px;`
        : 'height:100%;min-height:80px;width:100%;'
    "
    class="chart"
    :option="option"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { screenSizes } from "@/helper";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

interface IProps {
  data?: any;
  type?: string;
  isProfit?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  isProfit: true,
});
import { lineChart } from "./line_chart";
import { lineAreaChart } from "./line_area_chart";

const option = computed(() => {
  if (props.type === `lineAreaChart`) {
    return lineAreaChart(props.data, props.isProfit).option.value;
  }
  return lineChart(props.data, props.isProfit).option.value;
});
</script>
<style>
/* .chart div:first-child,
.chart div:first-child canvas {
  width: 100% !important;
} */
</style>
