<template>
  <n-data-table
    remote
    ref="table"
    :loading="loading"
    :columns="columns"
    :data="data"
    :pagination="pagination"
    size="medium"
    :resizable="true"
    striped
    :bordered="false"
    :scrollbar-props="{ trigger: 'none' }"
  />
  <DialogConfirm
    :is-open="dialog.isOpen"
    :title="dialog.title"
    :message="dialog.message"
    @on-cancel="dialog.isOpen = $event"
    @on-confirm="onCancel"
  />
</template>

<script setup lang="ts">
import { h, reactive, ref, onMounted } from "vue";
import DialogConfirm from "@/components/popup/DialogConfirm";
import moment from "moment";
import {
  NTag,
  NButton,
  NDataTable,
  NText,
  DataTableColumns,
  useDialog,
} from "naive-ui";
import axios from "axios";
import type { GridTableHistoryInvestment } from "@/types";
import { notificationSuccess, notificationError } from "@/helper/notifications";
import { RouterLink } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const API_VELVET = process.env.VUE_APP_API_VELVET;
const API_VELVET_TOKEN = process.env.VUE_APP_API_VELVET_TOKEN;
const dialogNaive = useDialog();
const props = defineProps<{
  pammAccountId: string;
}>();

const loading = ref(false);
const pagination = reactive({
  page: 1,
  lastPage: 1,
  pageSize: 10,
  itemCount: 0,
  pageSlot: 4,
  showSizePicker: true,
  pageSizes: [10, 25, 50, 100],
  onChange: (page: number) => {
    pagination.page = page;
    fetchHistoryInvestment();
  },
  onUpdatePageSize: (pageSize: number) => {
    pagination.pageSize = pageSize;
    pagination.page = 1;
    fetchHistoryInvestment();
  },
});

const filters = reactive({
  sort_by: "created_at",
  sort_asc: false,
  search: "",
});

onMounted(() => {
  fetchHistoryInvestment();
});

const dialog = reactive({
  isOpen: false,
  title: "",
  message: "",
  requestId: "",
});

const columns: DataTableColumns<GridTableHistoryInvestment> = [
  {
    title: "No",
    key: "no",
    width: 50,

    className: "text-nowrap",
    render: (_, index) => {
      return `${index + 1}`;
    },
  },
  {
    title: "PAMM ID",
    key: "pamm_user_id",
    width: 100,

    className: "text-nowrap",
    render(row) {
      if (row.account) {
        return row.account.investor_detail.Id;
      }
      return "-";
    },
  },
  {
    title: "Investment ID",
    key: "investment_id",
    width: 240,
    className: "text-nowrap",
    render(row) {
      if (row.investment_id) {
        return h(
          RouterLink,
          {
            class: "pointer",
            to: `/pamm/investor-account/my-investment/${row.investment_id}`,
          },
          {
            default: () =>
              h(
                NButton,
                {
                  quaternary: true,
                },
                {
                  default: () => row.investment_id,
                }
              ),
          }
        );
      }
      return "-";
    },
  },
  {
    title: "Managers",
    key: "pamm_manager_id",
    width: 280,
    className: "text-nowrap",
    render(row) {
      if (row.manager) {
        return [
          h(
            NText,
            { strong: true, tag: "div" },
            { default: () => `Name : ${row.manager.manager_detail.Name}` }
          ),
          h(
            NText,
            { tag: "div" },
            { default: () => `ID: ${row.manager.manager_detail.Id}` }
          ),
        ];
      }
      return h(NText, {}, { default: () => `-` });
    },
  },
  {
    title: "Type",
    key: "action",
    width: 120,
    className: "text-nowrap",
  },
  {
    title: "Status",
    key: "status",
    width: 200,
    className: "text-nowrap",
    render(row) {
      if (row.status) {
        if (row.status === "rejected") {
          return [
            h(
              NTag,
              {
                round: true,
                type: "default",
                bordered: false,
                size: "small",
              },
              {
                default: () => row.status,
              }
            ),
            h(
              NButton,
              {
                size: "small",
                quaternary: true,
                circle: true,
                type: "warning",
                renderIcon: () => h(FontAwesomeIcon, { icon: "circle-info" }),
                onClick: () => {
                  dialogNaive.warning({
                    title: "Rejected",
                    content: row.detail.comment_of_admin,
                    positiveText: "Oke",
                  });
                },
              },
              { default: () => "" }
            ),
          ];
        }
        return h(
          NTag,
          {
            round: true,
            type:
              row.status === "done"
                ? "success"
                : row.status === "canceled"
                ? "error"
                : row.status === "rejected"
                ? "default"
                : "warning",
            bordered: false,
            size: "small",
          },
          {
            default: () => row.status,
          }
        );
      }
      return;
    },
  },
  {
    title: "Amount Withdraw / Fund in",
    key: "count",
    width: 220,
    className: "text-nowrap",
    render(row) {
      return `${row.count ? row.count.toFixed(2) + " USD" : "-"}`;
    },
  },
  {
    title: "Date - Time",
    key: "date",
    sorter: "default",
    width: 240,
    className: "text-nowrap",
    render(row) {
      return formatDate(row.date);
    },
  },
  {
    title: "Action",
    key: "actions",
    width: 120,
    className: "text-nowrap",
    render(row) {
      if (
        row.status === "pending" &&
        row.action !== "management" &&
        row.action !== "penalty"
      )
        return h(
          NButton,
          {
            size: "small",
            type: "error",
            ghost: true,
            onClick: () => {
              dialog.isOpen = true;
              dialog.message = `Are you sure want cancel this process with <strong>${
                row?.manager?.manager_detail?.Name || ""
              }</strong> money manager?`;
              dialog.title = "Confirm Cancel";
              dialog.requestId = row.detail.request_id;
            },
          },
          { default: () => "Cancel" }
        );
      return;
    },
  },
];

const data = ref([]);

function fetchHistoryInvestment() {
  loading.value = true;
  const options = {
    method: `GET`,
    url: `${API_VELVET}v1/pamm-user/${props.pammAccountId}/history`,
    headers: {
      "content-type": `application/json`,
      Authorization: `Bearer ${API_VELVET_TOKEN}`,
    },
    params: {
      period_start_date: 0,
      period_end_date: moment(new Date()).unix(),
      sort_asc: filters.sort_asc ? 1 : -1,
      sort_by: "date",
      page: pagination.page - 1,
      limit: pagination.pageSize,
    },
  };
  axios(options)
    .then((res) => {
      data.value = res.data.data;
      pagination.page = res.data.meta.page + 1;
      pagination.lastPage = res.data.meta.lastPage + 1;
      pagination.itemCount = res.data.meta.total;
    })
    .catch((err) => {
      notificationError(err);
    })
    .finally(() => {
      loading.value = false;
    });
}

function onCancel(event) {
  dialog.isOpen = event;
  axios
    .post(
      `${API_VELVET}v1/requests/cancel/${dialog.requestId}`,
      {},
      {
        headers: {
          "content-type": `application/json`,
          Authorization: `Bearer ${API_VELVET_TOKEN}`,
        },
      }
    )
    .then(() => {
      notificationSuccess("success");
      fetchHistoryInvestment();
    })
    .catch((err) => {
      notificationError(err);
    })
    .finally(() => {
      loading.value = false;
    });
}

function formatDate(date) {
  return moment.unix(date).format("DD/MM/YYYY - hh:mm a");
}
</script>
