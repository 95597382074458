export default {
  auth: {
    title: `Login Account`,
    password: `Password`,
    login: {
      title: `Login`,
      helper: `Enter your email and password`,
      forgot_password: `Forgot Password`,
      dont_have_an_account_yet: `Don't have an account yet`,
    },
    register: {
      title: `Sign Up`,
    },
  },
  global: {
    or: `or`,
  },
}
