<template>
  <div id="pamm-money-manager-list">
    <div class="container py-5">
      <div class="content">
        <div class="main-header">
          <h1 class="fs-sm-2">Money Managers List</h1>
          <p>PAMM Money Managers available on Prime Codex</p>
        </div>
        <div class="tab-selector">
          <div
            class="selector"
            :class="{ active: !isViewingMyInvestment }"
            @click="handleSwitchView('money-manager')"
          >
            <h3>Money Managers</h3>
          </div>
          <div
            class="selector"
            :class="{ active: isViewingMyInvestment }"
            @click="handleSwitchView('my-investment')"
          >
            <h3>My Investment</h3>
          </div>
        </div>

        <!-- Money Managers List -->
        <div class="money-manager-container">
          <MoneyManagerFilterBar
            :grid-view="gridView"
            :list-sort-by="listSortBy"
            :filters="filters"
            :investment="isViewingMyInvestment"
            :count-investment="userInvestment.length"
            @toggleView="toggleView"
            @onFilters="onFilters"
          />
          <div v-if="isLoading" class="grid-view-container">
            <n-space v-for="i in 3" :key="i" class="card-container" vertical>
              <n-space class="d-flex">
                <n-skeleton height="40px" width="40px" circle />
                <n-skeleton height="40px" width="120px" :sharp="false" />
              </n-space>
              <n-skeleton height="40px" :sharp="false" />
              <n-skeleton height="40px" :sharp="false" />
            </n-space>
          </div>
          <div v-else>
            <section v-if="!isViewingMyInvestment">
              <div v-if="pammManager?.meta?.total > 0">
                <div v-if="gridView" class="grid-view-container">
                  <div
                    v-for="data in pammManager.data"
                    class="card-container"
                    :key="data.id"
                  >
                    <InvestorCard :data="data" />
                  </div>
                </div>
                <div v-if="!gridView" class="table-view-container">
                  <grid-table-money-manager :pammManager="pammManager?.data" />
                </div>
                <n-pagination
                  class="mt-3 d-flex justify-content-end"
                  :end-index="pammManager.meta.lastPage"
                  :page="pammManager.meta.page"
                  :page-size="pammManager.meta.pageSize"
                  :item-count="pammManager.meta.total"
                  show-size-picker
                  :page-slot="4"
                  :page-sizes="[12, 24, 50, 100]"
                  :on-update:page="(page) => onUpdatePage('page', page)"
                  :on-update:page-size="
                    (pageSize) => onUpdatePage('pageSize', pageSize)
                  "
                />
              </div>
              <div v-if="pammManager?.meta?.total < 1" class="no-data">
                <p>No money managers available</p>
              </div>
            </section>
            <section v-else>
              <div v-if="userInvestment.length > 0">
                <div v-if="gridView" class="grid-view-container">
                  <div
                    class="card-container"
                    v-for="(data, index) in userInvestment"
                    v-bind:key="index"
                  >
                    <InvestmentCard :data="data" />
                  </div>
                </div>
                <div v-if="!gridView" class="table-view-container">
                  <grid-table-investment :user-investment="userInvestment" />
                </div>
              </div>
              <div class="no-data" v-if="userInvestment.length <= 0">
                <p>You don’t have any investment</p>
              </div>
            </section>
          </div>
        </div>
        <div v-if="isViewingMyInvestment && pamm_account.id">
          <h4 class="my-4 font-weight-bold">History Investment</h4>
          <div
            class="bg-white padding-responsive--md padding-responsive--lg rounded-10px"
          >
            <grid-table-history-investment :pamm-account-id="pamm_account.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvestorCard from "@/components/pamm/InvestorCard.vue";
import InvestmentCard from "@/components/pamm/InvestmentCard.vue";
import GridTableMoneyManager from "@/components/pamm/GridTableMoneyManager";
import GridTableInvestment from "@/components/pamm/GridTableInvestment";
import GridTableHistoryInvestment from "@/components/pamm/GridTableHistoryInvestment";
import MoneyManagerFilterBar from "@/components/pamm/MoneyManagerFilterBar.vue";
import axios from "axios";
import { loading } from "@/helper/loading";
import moment from "moment";
import { NPagination, NSpace, NSkeleton } from "naive-ui";
import { notificationError } from "@/helper/notifications";

const url = process.env.VUE_APP_API_VELVET;
const token = process.env.VUE_APP_API_VELVET_TOKEN;
const userData = JSON.parse(localStorage.getItem("userData")) ?? {};

export default {
  components: {
    InvestorCard,
    InvestmentCard,
    MoneyManagerFilterBar,
    GridTableMoneyManager,
    GridTableInvestment,
    GridTableHistoryInvestment,
    NPagination,
    NSpace,
    NSkeleton,
  },
  data() {
    return {
      isViewingMyInvestment: false,
      gridView: true,
      pammManager: {
        data: null,
        meta: {
          page: 1,
          lastPage: 1,
          count: 0,
          total: 0,
          pageSize: 12,
        },
      },
      listSortBy: [
        {
          label: "Created At",
          value: "created_at",
        },
        {
          label: "Id",
          value: "id",
        },
        {
          label: "Name",
          value: "name",
        },
      ],
      filters: {
        sort_by: "created_at",
        sort_asc: true,
        search: "",
        non_negative_profit: false,
      },
      userInvestment: [],
      isLoading: true,
      currPage: 1,
      numberOfPage: 1,
      pamm_account: {},
    };
  },
  methods: {
    onFilters(event) {
      this.filters = { ...this.filters, ...event };
      this.pammManager.meta.page = 1;
      if (this.isViewingMyInvestment) {
        this.userInvestment = [];
        this.fetchInvestment();
      } else {
        this.pammManager.data = null;
        this.fetchManager();
      }
    },
    async fetchManager() {
      loading(true);
      this.isLoading = true;
      try {
        const response = await axios.get(`${url}v1/pamm-manager`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            status: "active",
            search: this.filters.search,
            sort_by: this.filters.sort_by,
            sort_asc: this.filters.sort_asc ? 1 : -1,
            page: this.pammManager.meta.page - 1,
            limit: this.pammManager.meta.pageSize,
          },
          timeout: 600000,
        });
        const data =
          (await this.filterManagerBySettings(await response.data.data)) || [];
        // this.filterManagerBySettings(await response.data.data);
        this.pammManager.data = (await response.data.data) ? [] : null;
        this.pammManager.meta = await {
          ...response.data.meta,
          lastPage: response.data.meta?.lastPage + 1,
          page: response.data.meta?.page + 1,
          pageSize: this.pammManager.meta.pageSize,
        };
        const idUserManagerInvestment = this.userInvestment.map((item) =>
          item.manager?.manager_detail?.Id
            ? item.manager?.manager_detail?.Id
            : item.manager.id
        );
        await data.forEach(async (item, index) => {
          const twelveHoursAgo = moment(new Date()).subtract(2, "weeks").unix();
          const endOfHours = moment(new Date()).unix();
          const options = {
            method: `GET`,
            url: `${url}v1/pamm-manager/${
              item.id ? item.id : item._id
            }/performance-history/profit`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              period_start_date: twelveHoursAgo,
              period_end_date: endOfHours,
              period_type: "daily",
            },
          };
          const performanceHistory = await axios(options);

          const dataX = await performanceHistory.data.data.map((item) =>
            moment(item.period_start_date).format("MM/DD/YYYY")
          );
          const dataY = await performanceHistory.data.data.map(
            (item) => item.profit
          );
          const idManager = (await item?.manager_detail?.Id)
            ? item?.manager_detail?.Id
            : item.id;
          const idx = await idUserManagerInvestment.findIndex(
            (item) => item === idManager
          );

          await this.pammManager.data.push({
            ...item,
            index,
            isUserInvestment: idx !== -1,
            idUserInvestment: idx !== -1 ? this.userInvestment[idx].id : 0,
            statusInvestment: idx !== -1 ? this.userInvestment[idx].status : 0,
            performanceHistory: { dataX, dataY },
          });
          this.pammManager.data = await this.pammManager.data.sort(
            (a, b) => a.index - b.index
          );
        });
      } catch (error) {
        notificationError(error);
      }
      this.isLoading = false;
      loading(false);
    },
    async filterManagerBySettings(dataManager) {
      const environment = process.env.VUE_APP_MODE;
      let isProduction = environment == "production";

      // console.log("data managers -> ", dataManager);

      if (isProduction) {
        return dataManager?.filter(
          (val) =>
            val.manager_settings?.is_test !== true &&
            val.manager_settings?.is_public !== false
        );
      } else {
        return dataManager?.filter(
          (val) => val.manager_settings?.is_public !== false
        );
      }
    },
    async fetchInvestment() {
      this.isLoading = true;
      this.userInvestment = [];
      loading(true);
      try {
        const response = await axios.get(
          `${url}v1/invest?user_id=${this.pamm_account.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              search: this.filters.search,
              sort_by: this.filters.sort_by,
              sort_asc: this.filters.sort_asc ? 1 : -1,
            },
          }
        );
        const data = response.data.data
          ? response.data.data.filter(
              (item) =>
                item.status !== `disconnected` &&
                item.status !== "canceled" &&
                item.status !== "rejected"
            )
          : [];
        await data.forEach(async (item, index) => {
          const twelveHoursAgo = moment(new Date()).subtract(2, "weeks").unix();
          const endOfHours = moment(new Date()).unix();
          const options = {
            method: `GET`,
            url: `${url}v1/pamm-account/${
              item.account.id || item.account._id
            }/performance-history/profit`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            params: {
              period_start_date: twelveHoursAgo,
              period_end_date: endOfHours,
              period_type: "daily",
            },
          };
          const performanceHistory = await axios(options);
          if (performanceHistory.data.error) {
            this.userInvestment.push({
              ...item,
              performanceHistory: { dataX: [], dataY: [] },
            });
          } else {
            const dataX = performanceHistory.data.data.map((item) =>
              moment(item.period_start_date).format("DD-MM-YYYY")
            );
            const dataY = performanceHistory.data.data.map(
              (item) => item.profit
            );

            this.userInvestment.push({
              index,
              ...item,
              performanceHistory: { dataX, dataY },
            });
          }
          this.userInvestment = await this.userInvestment.sort(
            (a, b) => a.index - b.index
          );
        });
        this.fetchManager();
      } catch (error) {
        notificationError(error);
        this.isLoading = false;
        loading(false);
      }
    },

    onUpdatePage(page, count) {
      if (page && count !== undefined) {
        if (page === "pageSize") {
          this.pammManager.meta.pageSize = count;
          this.pammManager.meta.page = 1;
        } else {
          this.pammManager.meta.page = count;
        }
        this.fetchManager();
      }
    },
    countJoinFee(data) {
      if (data?.manager_detail?.IncentiveAccounts) {
        const initialValue = 0;
        const joinFee = data.manager_detail.IncentiveAccounts;

        const sumWithInitial = joinFee.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.ManagementFee,
          initialValue
        );
        return sumWithInitial;
      }
      return 0;
    },
    handleSwitchView(view) {
      if (view !== "string") {
        this.filters = {
          sort_by: "created_at",
          sort_asc: true,
          search: "",
          non_negative_profit: false,
        };
        this.$router.replace(`/pamm/investor-account/${view}`);
        if (view === "my-investment") {
          this.isViewingMyInvestment = true;
          // this.fetchInvestment();
        } else {
          this.isViewingMyInvestment = false;
          // this.fetchManager();
        }
      }
    },
    toggleView: function (state) {
      this.gridView = state;
    },
    async fetchPammUser(email) {
      const options = {
        method: `GET`,
        url: `${url}v1/pamm-user`,
        headers: {
          "content-type": `application/json`,
          Authorization: `Bearer ${token}`,
        },
        params: {
          email: email,
          status: "active",
          limit: 1,
        },
      };

      await axios(options)
        .then((res) => {
          const data = res.data.data;
          this.pamm_account =
            res.data.data && res.data.data.length > 0 ? res.data.data[0] : null;
          if (data.length == 0) {
            this.$router.push("/pamm-register");
          } else if (this.pamm_account) {
            this.fetchInvestment();
          }
        })
        .catch((error) => {
          notificationError(error);
        });
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    // console.log("User login", user, user.user.email);
    window.scrollTo(0, 0);
    this.isViewingMyInvestment = this.$route.params.type === "my-investment";
    this.fetchPammUser(user?.user?.email);
  },
};
</script>
