import { createRouter, createWebHistory } from "vue-router"

import routes from "./routes"
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)
  if (to.matched.some((item) => item.meta.auth)) {
    if (localStorage.getItem(`userData`)) {
      next()
    } else {
      next(`/`)
    }
  } else {
    next()
  }
})

export default router
