import MasterLayout from "@/layouts/MasterLayout.vue"
import PAMMMoneyManagerList from "@/pages/pamm/PAMMMoneyManagerList.vue"
const routes = [
  {
    path: `/`,
    component: () => import(`../layouts/LandingPageLayout.vue`),
    children: [
      {
        path: ``,
        name: `Login`,
        component: () => import(`../pages/auth/Login.vue`),
      },
      {
        path: `/register`,
        name: `Register`,
        component: () => import(`../pages/auth/Register.vue`),
      },
      {
        path: `/auth/activate`,
        name: `Activate`,
        component: () => import(`../pages/auth/Activate.vue`),
      },
      {
        path: `/logout`,
        name: `Logout`,
        component: () => import(`../pages/auth/Logout.vue`),
      },
      {
        path: `/forgot-password`,
        name: `ForgotPassword`,
        component: () => import(`../pages/auth/ForgotPassword.vue`),
      },
      {
        path: `/reset-password/:utoken`,
        name: `ResetPassword`,
        component: () => import(`../pages/auth/ResetPassword.vue`),
      },
      {
        path: `/welcome`,
        name: `Welcome`,
        component: () => import(`../pages/auth/Welcome.vue`),
      },
    ],
  },
  {
    path: `/dashboard`,
    component: MasterLayout,
    children: [
      {
        path: ``,
        name: `Dashboard`,
        component: () => import(`../pages/dashboard/Dashboard.vue`),
        meta: { auth: true },
      },
      {
        path: `/deposit`,
        name: `Deposit`,
        component: () => import(`../pages/deposit/Index.vue`),
        meta: { auth: true },
      },
      {
        path: `/deposit-crypto`,
        name: `DepositCrypto`,
        component: () => import(`../pages/deposit/DepositCrypto.vue`),
        meta: { auth: true },
      },
      {
        path: `/deposit-credit-card`,
        name: `DepositCreditCard`,
        component: () => import(`../pages/deposit/DepositCreditCard.vue`),
        meta: { auth: true },
      },
      {
        path: `/verify-time`,
        name: `VerifyTimer`,
        component: () => import(`../pages/deposit/VerifyTimer.vue`),
        meta: { auth: true },
      },
      {
        path: `/withdraw`,
        name: `Withdraw`,
        component: () => import(`../pages/withdraw/Withdraw.vue`),
        meta: { auth: true },
      },
      {
        path: `/withdraw-crypto`,
        name: `WithdrawCrypto`,
        component: () => import(`../pages/withdraw/WithdrawCrypto.vue`),
        meta: { auth: true },
      },
      {
        path: `/partnership`,
        name: `Partnership`,
        component: () => import(`../pages/partnership/Partnership.vue`),
        meta: { auth: true },
      },
      {
        path: `/partnership/child`,
        name: `PartnershipChild`,
        component: () => import(`../pages/partnership/PartnershipChild.vue`),
        meta: { auth: true },
      },
      {
        path: `/identity`,
        name: `Identity`,
        component: () => import(`../pages/identity/VerifyIdentity.vue`),
        meta: { auth: true },
      },
      {
        path: `/verify-poa`,
        name: `VerifyBank`,
        component: () => import(`../pages/identity/VerifyPoA.vue`),
        meta: { auth: true },
      },
      {
        path: `/profile`,
        name: `Profile`,
        component: () => import(`../pages/profile/Profile.vue`),
        meta: { auth: true },
      },
      {
        path: `/create-account`,
        name: `CreateAccount`,
        component: () => import(`../pages/profile/CreateAccount.vue`),
        meta: { auth: true },
      },
      {
        path: `/create-account-demo`,
        name: `CreateAccountDemo`,
        component: () => import(`../pages/profile/CreateAccountDemo.vue`),
        meta: { auth: true },
      },
      {
        path: `/create-account-institutional`,
        name: `CreateAccountInstitutional`,
        component: () => import(`../pages/profile/CreateAccountInstitutional.vue`),
        meta: { auth: true },
      },
      {
        path: `/create-account-invlinic`,
        name: `CreateAccountInvlinic`,
        component: () => import(`../pages/profile/CreateAccountInvlinic.vue`),
        meta: { auth: true },
      },
      {
        path: `/my-account`,
        name: `MyAccount`,
        component: () => import(`../pages/dashboard/MyAccount.vue`),
        meta: { auth: true },
      },
      {
        path: `/info-account`,
        name: `infoAccount`,
        component: () => import(`../pages/profile/infoAccount.vue`),
        meta: { auth: true },
      },
      {
        path: `/verify-pending`,
        name: `verifyPending`,
        component: () => import(`../pages/identity/verifyPending.vue`),
        meta: { auth: true },
      },
      {
        path: `/change-password`,
        name: `ChangePassword`,
        component: () => import(`../pages/profile/ChangePassword.vue`),
        meta: { auth: true },
      },
      {
        path: `/promotion`,
        name: `Promotion`,
        component: () => import(`../pages/promotion/Promotion.vue`),
        meta: { auth: true },
      },
      {
        path: `/internal-transfer`,
        name: `InternalTransfer`,
        component: () => import(`../pages/transfer/InternalTransfer.vue`),
        meta: { auth: true },
      },
      {
        path: `/join-ib`,
        name: `JoinIB`,
        component: () => import(`../pages/partnership/JoinIB.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm`,
        name: `PAMM`,
        component: () => import(`../pages/pamm/PAMM.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm-register`,
        name: `RegisterPamm`,
        component: () => import(`../pages/pamm/PAMMRegister.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/my-investment`,
        name: `SummaryList`,
        component: () => import(`../pages/pamm/SummaryList.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/investor-account`,
        name: `PAMMInvestorAccount`,
        component: () => import(`../pages/pamm/PAMMInvestorAccount.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/investor-account/:type`,
        name: `PAMMMoneyManagerList`,
        component: PAMMMoneyManagerList,
        meta: { auth: true },
      },
      {
        path: `/pamm/investor-account/money-managers/:id`,
        name: `PAMMMoneyManagerDetail`,
        component: () => import(`../pages/pamm/PAMMManagerDetail.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/investor-account/my-investment/:id`,
        name: `PAMMMyInvestmentDetail`,
        component: () => import(`../pages/pamm/PAMMMyInvestmentDetail.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/register-account/money-managers`,
        name: `PAMMRegisterMoneyManager`,
        component: () =>
          import(`../pages/pamm/moneyManager/registerMoneyManger/NewIndex.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/money-managers/coming-soon`,
        name: `PAMMMoneyManagersComingSoon`,
        component: () => import(`../pages/pamm/moneyManager/ComingSoon.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/money-managers/list-account`,
        name: `PAMMListAccount`,
        component: () => import(`../pages/pamm/moneyManager/ListAccount.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/money-managers/account/:id`,
        name: `PAMMAccount`,
        component: () => import(`../pages/pamm/moneyManager/DetailAccount.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/fund-in/money-managers/:id`,
        name: `PAMMFundIn`,
        component: () => import(`../pages/pamm/PAMMFundIn.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/fund-in/my-investment/:id`,
        name: `PAMMMyInvestmentFundIn`,
        component: () => import(`../pages/pamm/PAMMMyInvestmentFundIn.vue`),
        meta: { auth: true },
      },
      {
        path: `/pamm/:id`,
        name: `MoneyManagersDetail`,
        component: () => import(`../pages/pamm/PAMMDetail.vue`),
        meta: { auth: true },
      },
    ],
  },

  // PAGE NOT FOUND
  {
    path: `/404`,
    component: () => import(`../pages/public/PageNotFound.vue`),
  },
  {
    path: `/:catchAll(.*)`, // Unrecognized path automatically matches 404
    redirect: `/404`,
  },
]

export default routes
