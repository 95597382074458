const sizes = { sm: 600, md: 1024, lg: 1440, xl: 1920 }

export const formatDateTime = (date) => {
  const newDate = new Date(date).getTime()
  return new Date(newDate).toLocaleString()
}

export const screenSizes = {
  xs: window.innerWidth < sizes.sm,
  sm: window.innerWidth >= sizes.sm && window.innerWidth < sizes.md,
  md: window.innerWidth >= sizes.md && window.innerWidth < sizes.lg,
  lg: window.innerWidth >= sizes.lg && window.innerWidth < sizes.xl,
  xl: window.innerWidth >= sizes.xl,
  // less than
  lt: {
    sm: window.innerWidth < sizes.sm,
    md: window.innerWidth < sizes.md,
    lg: window.innerWidth < sizes.md,
    xl: window.innerWidth < sizes.md,
  },
  // greater than
  gt: {
    xs: window.innerWidth > sizes.sm,
    sm: window.innerWidth > sizes.md,
    md: window.innerWidth > sizes.lg,
    lg: window.innerWidth > sizes.xl,
  },
}
