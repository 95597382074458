<template>
  <div>
    <div :class="sidebar_active ? 'sidebar-active' : 'sidebar-unactive'">
      <Sidebar
        :toggleSidebar="toggleSidebar"
        :sidebarMobileActive="active"
        @toggleSidebarMobileActive="toggleSidebarMobileActive"
      />
      <div
        :style="isMobile ? 'margin-top: 4.5rem' : ''"
        @click="toggleSidebarMobileActive"
      >
        <div v-if="isShowNotice" class="w-100 bg-warning text-center d-block">
          <p class="font-italic py-2">
            *Notice: Please change your trading account's password regularly!
          </p>
        </div>
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Sidebar,
    Footer,
  },
  data() {
    return {
      sidebar_active: true,
      active: true,
      isShowNotice: false,
    };
  },
  computed: {
    isMobile() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleSidebar: function () {
      this.sidebar_active = !this.sidebar_active;
    },
    toggleSidebarMobileActive(event) {
      if (this.isMobile) {
        this.active = typeof event !== "object" ? event : false;
      }
    },
  },
};
</script>

<style></style>
